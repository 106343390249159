body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.common-bg {
  background: #faad14;
  color: #fff;
}
.system-title {
  margin: 0;
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* layout page design start */
.ant-layout-sider-has-trigger {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}
.ant-layout-sider-has-trigger > .ant-layout-sider-trigger {
  width: 250px !important;
}
.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}
.ant-layout-sider-collapsed > .ant-layout-sider-trigger {
  width: 80px !important;
}
.ant-layout-sider-children {
  position: fixed;
  max-width: 250px;
  min-width: 250px;
  width: 250px;
  transition: all 0.05s ease-in-out;
  overflow: auto;
}
.ant-layout-sider-collapsed > .ant-layout-sider-children {
  max-width: 80px;
  min-width: 80px;
  width: 80px;
}
.user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.global-loading {
  opacity: 0.5;
  pointer-events: none;
}
.ant-menu-item-selected {
  background-color: #faad14 !important;
}
/* layout page design end */

/* login page start */
.main-login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  /* background: radial-gradient(#f3a445, #f88f0f); */
  background: linear-gradient(
    45deg,
    #f7941d 10%,
    #f7941d 40%,
    #f4d400 40%,
    #f4d400 60%,
    #ed1c24 60%,
    #ed1c24 65%,
    #ffae1e 65%,
    #ffae1e 90%
  );
}
.login-content {
  border-radius: 10px;
  overflow: hidden;
  width: 720px;
  height: 500px;
}
.login-form {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0 10px;
  position: absolute;
  background: #000000;
}
.login-left {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #dcd7e0, #fff);
}
.login-submit {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
.login-input-field {
  margin-top: 85px;
}
.login-form-button {
  width: 180px;
  background: radial-gradient(#faad14, #e69b05);
}
.login-image-container {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 10px;
}
.login-text {
  width: 100%;
  text-align: center;
  margin: 60px 0;
}
.login-text > h1 {
  color: rgb(246, 241, 241);
  font-size: 25px;
  line-height: 28px;
}
.login-input-username input::placeholder {
  color: rgb(220, 210, 210) !important;
}
.login-pass > input {
  background-color: #272b2e !important;
  color: white;
}
.login-pass > .ant-input-suffix > .anticon-eye-invisible {
  color: white !important;
}
.login-input-username input:-webkit-autofill,
.login-input-username input:-webkit-autofill:hover,
.login-input-username input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #272b2e inset;
  transition: background-color 5000s ease-in-out 0s;
}
.login-pass > input:-webkit-autofill,
.login-pass > input:-webkit-autofill:hover,
.login-pass > input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #272b2e inset;
  transition: background-color 5000s ease-in-out 0s;
}
.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  object-fit: contain;
}

.carousel-content {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 16px;
  position: absolute;
  bottom: 15px;
  width: 100%;
  text-align: justify;
}
@media screen and (max-width: 770px) {
  .login-left-container {
    display: none;
  }
}
/* login page end */

/* filter page start */
.filter-calender,
.filter-btn {
  width: 100%;
}
/* filter page end */

/* tooltip style */
.custom-tooltip {
  background: linear-gradient(90deg, #9a94ee 0%, #1c55f1 100%);
  color: #fff;
  padding: 8px 12px;
  border-radius: 10px;
}
.custom-tooltip > h6,
.custom-tooltip > p {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
.custom-tooltip > p {
  font-size: 16px;
}

/* common heading and box style start */
.box-container {
  min-height: 175px;
}
.box-container,
.component-box-container {
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px #00000040;
}
.component-box-container {
  margin-top: 15px;
}
.box-heading {
  background: #faad14;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  padding: 10px 5px;
  color: #fff;
}
.box-body {
  padding: 10px 5px 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-footer-container {
  text-align: center;
  padding: 0 0 5px 0;
}
.box-footer-content {
  color: #000;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}
.box-footer-content > span {
  font-weight: 600;
}
/* common heading and box style end */

/* menu scrollbar customize start */

/* Works on Firefox */
.ant-layout-sider-children {
  scrollbar-width: thin !important;
  scrollbar-color: blue !important;
}

/* Works on Chrome, Edge, and Safari */
.ant-layout-sider-children::-webkit-scrollbar {
  width: 0px !important;
}
.ant-layout-sider-children:hover::-webkit-scrollbar {
  width: 3px !important;
  position: absolute;
}

.ant-layout-sider-children::-webkit-scrollbar-track {
  background: #002140 !important;
}

.ant-layout-sider-children::-webkit-scrollbar-thumb {
  background-color: blue !important;
  border-radius: 20px !important;
  border: 1px solid #fff !important;
}
/* menu scrollbar customize end */

/* file upload style */
/* file upload button */
.training-file::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
.training-file::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
.training-file::file-selector-button:active {
  background-color: #e5e7eb;
}

/* data management start */
.data-box {
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgb(221, 220, 220);
}
/* data management end */

/* profile component style */
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.profile-card {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.settings-button-container {
  position: absolute;
  top: 8px;
  right: 8px;
}
.profile-card h2 {
  margin-bottom: 8px;
}

.profile-card p {
  margin: 4px 0;
}

.profile .bio {
  margin-top: 16px;
  text-align: center;
}

.password-change {
  max-width: 400px;
  margin-top: 20px;
}

.log-data {
  max-width: 600px;
  margin-top: 20px;
}

.log-data button {
  margin-bottom: 10px;
}

.log-data .ant-list-item {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 8px 12px;
  margin-bottom: 8px;
}

.registration-date {
  font-size: 12px;
  color: #888;
}
/* superuser add user component style start */
.add-user-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  background-color: #f5f5f5;
}

.add-user-form {
  max-width: 720px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.add-user-form h2 {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  color: #1890ff;
}
/* superuser add user component style end */

/* execution style start */
.execution-item {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
}
.ex-item-point {
  margin: 2px 0;
}
.ex-item-point-b {
  font-weight: 500;
}
.execution-item-error > div > .ex-item-point {
  color: #fff;
}
/* execution style end */

/* ticket page style start */
/* TicketForm.css */
.ticket-form-container {
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.ticket-form-container h2 {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  color: #1890ff;
}

.ticket-form-container .ant-form-item {
  margin-bottom: 24px;
}

.ticket-form-container .ant-form-item-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.ticket-form-container .ant-select {
  width: 100%;
}

.ticket-form-container .ant-btn {
  width: 100%;
}
/* TicketTable.css */
.ticket-table-container {
  max-width: 800px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.ticket-table-container h2 {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  color: #1890ff;
}

.ticket-table-container .ant-table {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.ticket-table-container .ant-table th,
.ticket-table-container .ant-table td {
  border: 1px solid #e8e8e8;
}

.ticket-table-container .ant-table-thead > tr > th {
  background-color: #f5f5f5;
  text-align: center;
}

.ticket-table-container .ant-table-tbody > tr > td {
  text-align: center;
}

.ticket-table-container .ant-btn {
  margin-right: 8px;
}
/* TicketPage.css */
.ticket-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.ticket-page-container h1 {
  text-align: center;
  margin-bottom: 24px;
  font-size: 36px;
  color: #1890ff;
}

.ticket-form-section {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
}

.ticket-table-section {
  width: 100%;
  max-width: 800px;
}
/* ticket page style end */

/* material allocate page style */
.file-upload-container {
  display: flex;
  align-items: center;
}

.custom-file-upload {
  background-color: #faad14;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.upload-icon {
  margin-right: 5px;
  width: 20px; /* Adjust the width and height to fit your icon size */
  height: 20px;
}

#file-upload {
  display: none;
}

.selected-file-name {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}

/* material movement page */
.input-container {
  margin-bottom: 20px;
}

.required-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #dc2020;
}
/* collapse style */
.ant-collapse-header {
  background-color: rgb(237, 229, 229);
  border-radius: 10px !important;
}
/*======================
    404 page
=======================*/
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.content {
  text-align: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.largeText {
  font-size: 5rem;
  margin-bottom: 0.5rem;
  color: #ff5733;
}

.mediumText {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.smallText {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #555;
}

.link {
  text-decoration: none;
  color: #007bff;
  font-weight: 500;
  transition: color 0.2s;
}

.link:hover {
  color: #0056b3;
}
.dropdown-content {
  z-index: 9999999999 !important;
}

/* DotLoader.css */
.dot-loader {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #a9a5a5;
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* game style */
.show-game-container {
  width: 100%;
  text-align: center;
  margin: -40px 0 10px 0;
}
.show-game-button {
  border: none;
  text-decoration: underline;
  color: rgb(116, 116, 116);
  background: none;
}
.tie-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.game-board {
  display: grid;
  grid-template-columns: repeat(3, 80px);
  gap: 10px;
}

.game-cell {
  width: 80px;
  height: 80px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  font-size: 36px;
  font-weight: bold;
  cursor: pointer;
}

.game-cell.X {
  color: blue;
}

.game-cell.O {
  color: red;
}

.game-reset-button {
  margin: 10px 0;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}
.winner {
  display: none;
  font-size: 24px;
  margin: 10px;
}

.winner.show {
  display: block;
  animation: gameFadeIn 2s;
}

/* New styles for the score display */
.scoreboard {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

/* Add keyframes for fadeIn animation */
@keyframes gameFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
